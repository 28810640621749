import React, { useEffect, useRef } from 'react';

import API from 'files/api.js';
import Appearance from 'styles/Appearance.js';
import Utils from 'files/Utils.js';

export const Footer = ({ dealership }) => {

    const getContent = () => {

        if(!dealership) {
            return null;
        }

        // determine if a mobile layout was requested 
        if(Utils.isMobile() === true) {
            return (
                <div style={{
                    alignItems: 'center',
                    backgroundColor: dealership.colors.primary || '#232323',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 24,
                    textAlign: 'center',
                    width: '100%'
                }}>
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: 12,
                        width: 250
                    }}>
                        <span style={{
                            color: 'white',
                            fontSize: 18,
                            fontFamily: Appearance.fonts.getFamilyForWeight(700),
                            marginBottom: 2,
                        }}>{`© 2024 ${dealership.name}`}</span>
                        <span style={{
                            color: 'white',
                            fontSize: 12,
                            fontFamily: Appearance.fonts.getFamilyForWeight(500),
                        }}>{`Version ${API.app_version} Build ${API.build}`}</span>
                    </div>
                    <span 
                    className={'text-button'}
                    onClick={window.open.bind(this, 'https://homesafealerts.com/dealers')}
                    style={{
                        color: 'white',
                        fontSize: 16,
                        fontFamily: Appearance.fonts.getFamilyForWeight(600),
                        marginBottom: 12,
                        textDecoration: 'underline',
                        width: 250
                    }}>{'Dealer Login'}</span>
                    <span 
                    className={'text-button'}
                    onClick={window.open.bind(this, 'https://duralabs.io')}
                    style={{
                        color: 'white',
                        fontSize: 16,
                        fontFamily: Appearance.fonts.getFamilyForWeight(600),
                        textDecoration: 'underline',
                        width: 250
                    }}>{`Designed by Dura Labs LLC`}</span>
                </div>
            )
        }

        return (
            <div style={{
                alignItems: 'center',
                backgroundColor: dealership.colors.primary || '#232323',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: '2vw',
                width: '100%'
            }}>
                <span 
                className={'text-button'}
                onClick={window.open.bind(this, 'https://duralabs.io')}
                style={{
                    color: 'white',
                    fontSize: 16,
                    fontFamily: Appearance.fonts.getFamilyForWeight(600),
                    textDecoration: 'underline',
                    width: 250
                }}>{`Designed by Dura Labs LLC`}</span>
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    width: 250
                }}>
                    <span style={{
                        color: 'white',
                        fontSize: 18,
                        fontFamily: Appearance.fonts.getFamilyForWeight(700),
                        marginBottom: 2,
                        textAlign: 'center'
                    }}>{`© 2024 ${dealership.name}`}</span>
                    <span style={{
                        color: 'white',
                        fontSize: 12,
                        fontFamily: Appearance.fonts.getFamilyForWeight(500),
                    }}>{`Version ${API.app_version} Build ${API.build}`}</span>
                </div>
                <span 
                className={'text-button'}
                onClick={window.open.bind(this, 'https://homesafealerts.com/dealers')}
                style={{
                    color: 'white',
                    fontSize: 16,
                    fontFamily: Appearance.fonts.getFamilyForWeight(600),
                    textDecoration: 'underline',
                    textAlign: 'right',
                    width: 250
                }}>{'Dealer Login'}</span>
            </div>
        )
    }

    return getContent();
}

export const NavigationSectionHeader = ({ dealership, identifier, title, utils }) => {

    const ref = useRef(null);

    const getContent = () => {
        return dealership && (
            <div 
            className={'diamonds'}
            id={`navigation_component_${identifier}`}
            ref={ref}
            style={{
                alignItems: 'center',
                backgroundColor: dealership.colors.primary || Appearance.colors.dim,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                lineHeight: 1,
                padding: Utils.isMobile() === true ? 36 : '5vw',
                position: 'relative',
                textAlign: Utils.isMobile() === true ? 'center' : 'left',
                width: '100%'
            }}>
                <span style={{
                    color: 'white',
                    fontSize: 48,
                    fontFamily: Appearance.fonts.getFamilyForWeight(800),
                    paddingTop: Utils.isMobile() === true ? 12 : 0
                }}>{title.toUpperCase()}</span>
            </div>
        )
    }

    useEffect(() => {
        if(ref.current) {
            utils.navigation.setWaypointObserver(ref.current, identifier);
        }
    }, [ref.current]);
    
    return getContent();
}