import React, { useEffect, useState } from 'react';
import API from 'files/api.js';

export const LeadGenerationSurvey = ({ dealership }) => {

    const [height, setHeight] = useState(window.innerHeight);

    const onHeightChange = evt => {
        if(evt.data.height) {
            setHeight(evt.data.height + 80);
        }
    }
    
    useEffect(() => {

        // add event listener for iframe contents height change
        window.addEventListener('message', onHeightChange);
        return () => {
            window.removeEventListener('message', onHeightChange);
        }

    }, []);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%'
            }}>
                <iframe 
                src={`${API.server}/leads/?id=${dealership.lead_generation_survey.id}&dealership_id=${dealership.id}&embeded=1`}
                style={{
                    border: 'none',
                    height: height,
                    width: '100%'
                }} />
            </div>
        </div>
    )
}

export const RecruitingSurvey = ({ dealership }) => {

    const [height, setHeight] = useState(window.innerHeight);

    const onHeightChange = evt => {
        if(evt.data.height) {
            setHeight(evt.data.height + 80);
        }
    }
    
    useEffect(() => {

        // add event listener for iframe contents height change
        window.addEventListener('message', onHeightChange);
        return () => {
            window.removeEventListener('message', onHeightChange);
        }

    }, []);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%'
            }}>
                <iframe 
                src={`${API.server}/leads/?id=${dealership.recruiting_survey.id}&dealership_id=${dealership.id}&embeded=1`}
                style={{
                    border: 'none',
                    height: height,
                    width: '100%'
                }} />
            </div>
        </div>
    )
}