import React, { useEffect, useState } from 'react';

import Appearance from 'styles/Appearance.js';
import LottieView from 'views/Lottie.js';
import Request from 'files/Request.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

export const GoogleReviews = ({ dealership, utils }) => {
    
    const [loading, setLoading] = useState(false);
    const [reviews, setReviews] = useState([]);

    const onGoogleReviewClick = () => {
        window.open(dealership.google_place.review_url);
    }

    const getContent = () => {
        if(loading === true) {
            return (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    maxWidth: 1250,
                    padding: Utils.isMobile() ? 24 : '2vw',
                    width: '100%'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 65,
                        width: 65
                    }}/>
                </div>
            )
        }
        return reviews.map((review, index) => {
            return (
                <div
                key={index}
                style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginBottom: index !== reviews.length - 1 ? 24 : 0,
                    maxWidth: 1250,
                    width: '100%'
                }}>
                    <div style={{
                        alignItems: 'center',
                        borderBottom: `1px solid ${Appearance.colors.divider()}`,
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: 0,
                        padding: Utils.isMobile() ? 24 : '2vw'
                    }}>
                        <img 
                        src={review.avatar}
                        style={{
                            height: 60,
                            marginRight: 18,
                            objectFit: 'cover',
                            width: 60
                        }} />
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            minWidth: 0
                        }}>
                            <span style={{
                                color: Appearance.colors.text(),
                                fontFamily: Appearance.fonts.getFamilyForWeight(700),
                                fontSize: 20
                            }}>
                                {review.full_name}
                            </span>
                            <span style={{
                                color: Appearance.colors.subText(),
                                fontFamily: Appearance.fonts.getFamilyForWeight(500),
                                fontSize: 16
                            }}>
                                {review.date_relative}
                            </span>
                        </div>
                        {getRatingComponents('desktop', review)}
                    </div>
                    {getRatingComponents('mobile', review)}
                    <div style={{
                        padding: Utils.isMobile() ? 24 : '2vw'
                    }}>
                        <span style={{
                            color: Appearance.colors.text(),
                            fontFamily: Appearance.fonts.getFamilyForWeight(500),
                            fontSize: 14
                        }}>
                            {review.text}
                        </span>
                    </div>
                </div>
            )
        });
    }

    const getGoogleReviewBanner = () => {
        return (
            <div 
            className={'text-button'}
            onClick={onGoogleReviewClick}
            style={{
                ...Appearance.styles.unstyledPanel(),
                borderWidth: 0,
                display: 'flex',
                flexDirection: 'column',
                marginBottom: Utils.isMobile() === true ? 24 : '2vw', 
                maxWidth: 750,
                position: 'relative',
                width: '100%'
            }}>
                <img
                src={'images/google-review-banner-background.jpg'}
                style={{
                    flexGrow: 1,
                    height: '100%',
                    position: 'absolute',
                    objectFit: 'cover',
                    width: '100%'
                }} />
                <div style={{
                    padding: Utils.isMobile() === true ? 12 : '1vw',
                    width: '100%',
                    zIndex: 2
                }}>
                    <div style={{
                        alignItems: 'center',
                        backgroundColor: Appearance.colors.panelBackground(),
                        borderRadius: 6,
                        display: 'flex',
                        flexDirection: 'column',
                        padding: Utils.isMobile() === true ? 24 : '2vw',
                        textAlign: 'center',
                        width: '100%'
                    }}>
                        <img
                        src={'images/google-review-icon.png'}
                        style={{
                            height: 65,
                            marginBottom: 24,
                            objectFit: 'contain',
                            width: 65
                        }} />
                        <span style={{
                            color: Appearance.colors.text(),
                            fontFamily: Appearance.fonts.getFamilyForWeight(800),
                            fontSize: Utils.isMobile() === true ? 24 : 26,
                            lineHeight: 1.2
                        }}>{'HOW ARE WE DOING?'}</span>
                        <span style={{
                            color: Appearance.colors.subText(),
                            fontFamily: Appearance.fonts.getFamilyForWeight(500),
                            fontSize: 18
                        }}>{'Click to leave us a Google review'}</span>
                    </div>
                </div>
            </div>
        )
    }

    const getRatingComponents = (view, review) => {

        // return mobile components if applicable
        if(view === 'mobile' && Utils.isMobile() === true) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginTop: 24
                }}>
                    {[1,2,3,4,5].map(val => (
                        <img 
                        key={val}
                        src={val <= review.rating ? 'images/google-review-star-yellow.png' : 'images/google-review-star-grey.png'}
                        style={{
                            height: 35,
                            marginLeft: 8,
                            width: 35
                        }} />
                    ))}
                </div>
            )
        }

        // return desktop components if applicable
        if(view === 'desktop' && Utils.isMobile() === false) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    {[1,2,3,4,5].map(val => (
                        <img 
                        key={val}
                        src={val <= review.rating ? 'images/google-review-star-yellow.png' : 'images/google-review-star-grey.png'}
                        style={{
                            height: 35,
                            marginLeft: 8,
                            width: 35
                        }} />
                    ))}
                </div>
            )
        }
    }

    const fetchReviews = async () => {
        try {
            setLoading(true);
            let response = await Request.get(utils, '/dealerships/', {
                dealership_id: dealership.id,
                type: 'google_place_reviews'
            });

            //console.log(response.result);
            setLoading(false);
            setReviews(response.reviews || []);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of google reviews. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchReviews();
    }, []);

    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '5vw',
            width: '100%'
        }}>
            {getGoogleReviewBanner()}
            {getContent()}
        </div>
    )
}